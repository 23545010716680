<template>
  <div  class="wrap">
    <div class="box-con">
      <!-- 广告管理 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
          <!-- <area-tree ref="cascader" @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree> -->
        </el-form-item>
        <el-form-item style="marginLeft:50px" label="广告类型：">
          <el-select v-model="queryParams.advType" @change="resetPageAndRefresh" clearable filterable placeholder="请选择广告类型">
            <el-option v-for="item in advStatusOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="marginLeft:50px" label="广告位置：">
          <el-select v-model="queryParams.posId" @change="resetPageAndRefresh" clearable filterable placeholder="请选择广告位置">
            <el-option v-for="item in positonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="fr">
          <el-button @click="isEditor=!isEditor" v-if="OA.includes('yygl:gggl:editor')"  :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '编辑'}}</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button @click="addAdvert" icon="el-icon-plus" v-if="OA.includes('yygl:gggl:add')" >新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="advertTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="advertList" :isEditor="isEditor" :total="advertTotal" @refresh="refresh" :span='12'>
        <template slot-scope="scope">
          <div class="list">
            <div class="con-head">
              <div class="area">
                <p class="con-txt" v-if="scope.row.advType==1">图片</p>
                <p class="con-txt" v-if="scope.row.advType==2">视频</p>
                <p class="con-txt" v-if="scope.row.advType==3">红包</p>
                <p class="con-txt" v-if="scope.row.advType==4">动态植入</p>
                <p class="con-txt" v-if="scope.row.advType==5">代码引用</p>
                <p class="mission">广告类型</p>
              </div>
              <div class="area">
                <p class="con-txt txtwidth" title="">{{ scope.row.positionName }}</p>
                <p class="mission">广告位置</p>
              </div>
              <div class="areas fr">
                <p class="con-txt tinctgreen" v-if="scope.row.advStatus==1">已开启</p>
                <p class="con-txt tinctgray" v-if="scope.row.advStatus==0">已关闭</p>
                <p class="con-txt tinctblue" v-if="scope.row.advStatus==2">未开始</p>
                <p class="con-txt tinctgreen" v-if="scope.row.advStatus==3">投放中</p>
                <p class="con-txt tinctgray" v-if="scope.row.advStatus==4">已结束</p>
                <p class="mission fr">广告状态</p>
              </div>
              <div class="area fr">
                <p class="con-txt">￥{{scope.row.cost?scope.row.cost:'-'}}</p>
                <p class="mission">实际收入</p>
              </div> 
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">广告标题</span>
                  <span class="condel" :title="scope.row.advTitle">{{scope.row.advTitle?scope.row.advTitle:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">广告描述</span>
                  <span class="condel" :title="scope.row.advInfo">{{scope.row.advInfo?scope.row.advInfo:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">投放时长</span>
                  <span class="condel">{{scope.row.tfsc?scope.row.tfsc:'-'}}天</span>
                </div>
                <div class="time">
                  <span class="contit">开始时间</span>
                  <span class="condel">{{scope.row.startDate?scope.row.startDate:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">排序</span>
                  <span class="condel">{{scope.row.advSort?scope.row.advSort:'-'}}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time">
                  <span class="contit">广告链接</span>
                  <span class="condel"><a href="">{{scope.row.advLnk?scope.row.advLnk:'-'}}</a></span>
                </div>
                <div class="time">
                  <span class="contit">投放公司</span>
                  <span class="condel">{{scope.row.organ?scope.row.organ:'-'}}</span>
                </div>
                <div class="time">
                  <span class="contit">收费方式</span>
                  <span class="condel" v-if="scope.row.chargeType==1">包年</span>
                  <span class="condel" v-if="scope.row.chargeType==2">包月</span>
                  <span class="condel" v-if="scope.row.chargeType==3">按千次</span>
                  <span class="condel" v-if="scope.row.chargeType==4">互动</span>
                </div>
                <div class="time">
                  <span class="contit">结束时间</span>
                  <span class="condel">{{scope.row.endDate?scope.row.endDate:'-'}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template  slot="control" slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="editAdver(scope.row)" v-if="OA.includes('yygl:gggl:editor')" circle></el-button>
          <el-button type="danger" @click="handleDelete({id: scope.row.id})" v-if="OA.includes('yygl:gggl:del')" icon="el-icon-delete" title="删除" circle></el-button>
          <!-- <el-button type="danger" icon="el-icon-delete" circle></el-button> -->
        </template>
      </paged-list>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'advertising',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return {
      active: 1,
      statusOptions:[],
      positonList: [],
      queryParams: {
        
      },
     advStatusOptions: [
       { status: 1, lable: "图片" },
       { status: 2, lable: "视频" },
       { status: 3, lable: "红包" },
       { status: 4, lable: "动态植入" },
       { status: 5, lable: "代码引用" },
     ],
      pageApi:'getPagesList',
      deleteApi: 'advertDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState('advertising',["advertList","advertTotal"])
  },
  methods: {
    ...mapActions('advertising',["getPagesList","advertAdd","advertEdit","advertLoad", "selectAllPosition", "advertDelete"]),
    // 获取所有广告位
    async requireAllPosition(){
      let res = await this.selectAllPosition({ pageNum: 1,pageSize: 100 })
      this.positonList = res
      console.log(res)
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    addAdvert() {
      this.$router.push({ path:'/newAdvertising' })
    },
    editAdver (row) {
      console.log(row)
      this.$router.push({ path:'/newAdvertising', query:{id: row.id, active: row.advStatus } })
    },
  },
  async mounted() {
    this.requireAllPosition()
    this.refresh()
  },
  activated(){
    this.refresh()
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtwidth{ width: 240px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .condel{color: $c-2; width: 200px; display: inline-block; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        }
      }
    }
  }
}
/deep/#el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;}
.allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 10px;
        .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%;}
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .d-condel{padding-top:20px;
    .headLine{ font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: bolder;color: $c-2;line-height: 16px; }
    .people{ overflow: hidden;
      .peo-head{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600; color: $c-2;line-height: 20px; margin: 20px 0;}
      .peo-con{ margin-bottom: 20px;
        .conMoney{ font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-danger;line-height: 20px;margin-right: 20px; }
        .con{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
      }
      .welfare{
        span{ border-radius: 4px; border: 1px solid $c-border;padding: 6px 10px; margin-right: 10px;}
      }
      .peo-time{margin-top: 20px;
        .time{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px;}
        .time-con{font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 14px;
        }
      }
    }
  }
  .d-conjob{ margin-top: 20px;padding-bottom:20px;
    .jobdel{ font-size: 20px; padding-top:20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; }
    .jobstate{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; margin-top: 10px;margin-bottom: 20px;}
    img{ width: 150px;height: 150px; background: skyblue;margin-right: 10px; border-radius: 4px;}
  }
  .d-apply{ margin-top: 20px;
    .applylist{
      .list{font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; margin-right: 20px;}
      .listdel{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
    }
    .headUrl{ margin:20px 5px;
      li { position:relative; float:left; width:50px; height:50px; margin:0 -5px; border:#fff 5px solid; border-radius: 50%; overflow:hidden; }
    }
  }
  .d-allComment{
    .comment{
      .list{font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; margin-right: 20px;}
      .listdel{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
    }
    .commentCon{ height: 300px; overflow-y:scroll;margin-top: 20px; background: #F5F7F9;padding: 20px;
      .person{ margin-bottom:20px;
        .head{ width: 50px; height: 50px;border-radius: 50%; display: inline-block;}
        .nameinfo{ width: 205px;display: inline-block;margin-left:20px;;
          .name{ color: $c-success;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 20px; }
          .perCom{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; }
        }
        .perTime{color: $c-9;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 20px;}
      }
    }
  }
  .d-btn{ height: 158px; width: 100%;text-align: center;padding-top:40px;
    .el-button{ width: 140px;height: 44px; }
    .active{ background: $c-success;color: $c-white !important; }
  }
}
.applyDetail{ padding: 20px;
  .search{ background: #fff;  border-radius: 4px; }
  .people{ width: 100%; min-height: 240px; background: #F6F6F6;border-radius: 6px; padding: 20px;margin-bottom:20px;
    .person{ margin-bottom:15px;
      .head{ width: 50px; height: 50px;border-radius: 50%; display: inline-block;}
      .nameinfo{ width: 205px;display: inline-block;margin-left:20px;;
        .name{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: $c-2;line-height: 14px; }
        .perCom{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400; color: #8E8E8E;line-height: 20px; }
        }
        .perTime{color: $c-9;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 20px;
          .apptime{color: $c-2;}
        }
    }
    .peoCon{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; }
    .contact{margin-top:20px;
      .tactbox{width: 30%; height: 44px;   line-height: 44px;background: $c-white;display: inline-block;margin-right: 3%;  position: relative; border-radius: 5px; border: 1px solid $c-border;
        .tactimg{ position: absolute; left: 8px;top: 13px; }
        .tactcon{ margin-left: 30px; font-size: 14px;}
      } 
    }
  }
}

</style>
